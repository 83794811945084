.music-section {
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 600px;
  margin: auto;
  border-radius: 10px;
}

.track-list {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.track-item {
  background: #333;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
}

.track-item.active {
  border: 2px solid #ffa500;
  background: #444;
}

.track-info h3 {
  font-size: 18px;
  margin: 0 0 5px;
}

.track-info input[type="range"] {
  width: 100%;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.volume-slider {
  writing-mode: bt-lr;
  -webkit-appearance: slider-vertical;
  width: 100%;
  max-height: 100px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.play-btn {
  background-color: green;
  color: #fff;
}

.pause-btn {
  background-color: orange;
  color: #fff;
}

.stop-btn {
  background-color: red;
  color: #fff;
}

.prev-btn,
.next-btn {
  background-color: #666;
  color: #fff;
}

button.active {
  border-color: rgb(163, 36, 36);
  background-color: rgba(255, 165, 0, 0.2);
}

/* Адаптивное расположение для мобильных устройств */
@media (max-width: 500px) {
  .music-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .track-list {
    flex: 1;
  }

  .controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-left: -15px;
  }

  .volume-slider {
    margin: 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
  }

  .buttons button {
    font-size: 16px;
    padding: 8px;
  }
}
