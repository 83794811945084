/* Глобальные стили для прокрутки */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Отключаем горизонтальную прокрутку */
}

/* Основные стили для секции видео */
.video-section {
  padding: 20px;
  text-align: center;
  overflow-y: auto; /* Добавляем вертикальную прокрутку */
}

.video-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Сетка для видео */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  justify-items: center;
}

/* Стили для миниатюр видео */
.local-video,
.embedded-video {
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #333;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

video,
.embedded-video iframe {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.video-title {
  font-size: 1.2rem;
  color: #ff9800;
  margin-top: 10px;
}

/* Адаптивное изменение для мобильных устройств */
@media (max-width: 600px) {
  .video-grid {
    grid-template-columns: 1fr; /* Одна колонка */
    gap: 15px;
  }

  .local-video,
  .embedded-video {
    max-width: 100%; /* На всю ширину экрана */
    padding: 5px;
  }
}
