/* Основной стиль для App */
.App {
  text-align: center;
}

/* Стили для header */
header {
  position: relative;
  background-color: #333;
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Перемещаем содержимое вправо */
}

/* Кнопка гамбургер-меню */
.menu-toggle {
  display: none;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  padding: 5px;
  position: absolute;
  top: 15px;
  right: 15px;
}

/* Основные стили навигации */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

/* Стили ссылок */
nav a {
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}

nav a:hover {
  color: #aaa;
}

/* Мобильные стили */
@media (max-width: 500px) {
  /* Отображение кнопки гамбургера */
  .menu-toggle {
    display: block;
    z-index: 1;
    color: #ff9800;
  }

  header {
    padding: 0;
  }

  /* Скрытие навигации по умолчанию */
  nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px; /* Открывается под кнопкой меню */
    right: 15px; /* Выравнивание по правому краю */
    background-color: #333;
    width: 150px;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  /* Открытое меню */
  nav.open {
    display: flex;
    z-index: 1;
  }

  /* Стили для ссылок в мобильном меню */
  nav a {
    padding: 12px;
    font-size: 16px;
    text-align: left;
    border-bottom: 1px solid #444;
  }

  nav a:last-child {
    border-bottom: none;
  }
  h1 {
    padding-bottom: 25px;
    padding-top: 15px !important;
  }
}
